/* ----- Controls styles ----------------- */
.btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: $btn-padding;
  font: $btn-font;
  border: none;
  border-radius: $btn-radius;
  text-align: center;
  text-decoration: none;
  outline: none;
  transition: ease 0.2s;
  cursor: pointer;
  color: $btn-color;
  background-color: $btn-fill-color;
  background-image: linear-gradient(to top, #f4792b 0%, #f9b13a 100%);
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
    color: $btn-color-hover;
    background-color: $btn-fill-hover-color;
    background-image: linear-gradient(to top, #f4792b 0%, #f9b13a 100%);
    box-shadow: 8px 13px 30px rgba(235, 106, 10, 0.3);
    background-clip: padding-box; }
  &:active {
    background-color: #f4832d;
    background-image: none;
    box-shadow: none; }

  &_second-st {
    background-color: none;
    background-image: linear-gradient(to top, rgba(#f4792b, 0.3) 0%, rgba(#f9b13a, 0.3) 100%);

    &:hover {
      background-color: $btn-fill-second-hover-color; } }

  &_border {
    border: $btn-border;
    color: $btn-border-color-text;
    line-height: $btn-border-line-height;
    background: none;

    &:hover {
      color: $btn-border-hover-color-text;
      background-color: $btn-border-hover-color;
      border-color: transparent;
      box-shadow: none; }
    &:active {
      background-color: #f4832d;
      background-image: none; }


    &.btn_second-st {
      border-color: #492f1b;
      color: $btn-fill-second-color;

      &:hover {
        color: $btn-border-hover-color-text;
        background-color: $btn-fill-second-color; } } }

  &_min-width {
    min-width: $btn-width-min;
    @include mob-md {
      min-width: $btn-width-min+40; } }

  &_max {
    width: 100%; }

  &_sm {
    padding: $btn-padding-sm;
    font-size: 13px;

    &.btn_min-width {
      min-width: $btn-width-min-sm; } }

  &_md {
    padding: $btn-padding-md;

    &.btn_min-width {
      min-width: $btn-width-min-md; } }

  &_lg {
    padding: $btn-padding-lg;

    &.btn_min-width {
      min-width: $btn-width-min-lg; } }

  &__icon-text {
    display: inline-block;
    vertical-align: middle; }

  &__icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #000;
    font-style: normal;

    &_right {
      margin-left: 5px; }

    &_left {
      margin-right: 5px; } }

  // &_font-bold
  //   font-family: $btn-font-family-bold
  //   font-weight: $btn-font-weight-bold

  // &_font-light
  //   font-family: $btn-font-family-light
  //   font-weight: $btn-font-weight-light

  // &_size-sm
  //   font-size: $btn-size-sm
  // &_size-md
  //   font-size: $btn-size-md
  // &_size-lg
  //   font-size: $btn-size-lg

  // &_up
  //   text-transform: uppercase

  // &_radius-sm
 }  //   border-radius: $btn-radius-sm

input.btn {
  padding-top: 0;
  padding-bottom: 0;
  height: $input-height;
  width: auto;
  &_max {
    width: 100%; } }

.form-group-grid__four-col {
  .btn {
    vertical-align: bottom;
    display: block; } }

.placeholder {
  color: $st-color-placeholder; }

input,
textarea {
  @include placeholder-color($st-color-placeholder); }

input:hover,
textarea:hover {
  @include placeholder-color($cl-mineShaft); }

::-webkit-contacts-auto-fill-button {
  opacity: 0; }

input[type="password"]::-webkit-contacts-auto-fill-button {
  opacity: 1; }

input,
textarea {
  position: relative;
  height: $input-height;
  width: 100%;
  padding: $input-padding;
  border: $input-border;
  font: $input-font;
  color: $input-color;
  background-color: $input-fill-color;
  outline: none;
  border-radius: $input-radius;
  -webkit-appearance: none;
  box-shadow: $input-shadow;


  &:hover {
    border-color: $input-hover-border-color; }

  &:focus {
    border-color: $input-focus-border-color; } }

textarea {
  vertical-align: top;
  height: $textarea-height;
  padding: $textarea-padding;
  resize: none; }

/* ----- END controls ------------------- */
/*                                       */
/*                                       */













