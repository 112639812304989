/* ----- Page structure ---------------- */
.clear {
  clear: both; }

.group:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden; }

#wrapper {
  min-width: 320px; }

.section-st {
  padding: 40px 0;
  &_light {
    background-color: #fcfeff; } }

.container {
  padding-left: 15px;
  padding-right: 15px;
  width: auto; }

.visible-lap, .hidden-full, .hidden-mobile {
  display: none; }

#middle {
  overflow: hidden;
  background-color: #fcfeff; }


/* Min width 768 pixels */
@media only screen and (min-width: 768px) {
  .hidden-mobile {
    display: block; }

  .hidden-tabl {
    display: none; }
  .section-st_sm.section-st {
    padding: 15px 0; }
  .section-st_push-bottom.section-st {
    padding: 15px 0 50px; }
  /* end media min width 768 pixels */ }


/* Min width 1000 pixels */
@media only screen and (min-width: 1000px) {
  html, body {
    height: 100%; }

  body {
    display: flex;
    flex-direction: column; }

  #wrapper {
    flex: 1 0 auto; }

  .footer {
    flex-shrink: 0; }

  .section-st {
    padding: 50px 0; }

  .container {
    max-width: 980px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto; }

  .visible-lap {
    display: block; }
  .hidden-tabl {
    display: block; }
  .hidden-lap {
    display: none; }
  /* end media min width 1000 pixels */ }


/* Min width 1200 pixels */
@media only screen and (min-width: 1200px) {
  .section-st_md {
    padding: 70px 0 70px; }
  .section-st_lg {
    padding: 80px 0; }
  .hidden-lap {
    display: block; }
  .container {
    max-width: 1140px;
    padding-left: 15px;
    padding-right: 15px; }
  /* end media min width 1200 pixels */ }

























