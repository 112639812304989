/* ----- Card ------------------- */

a.card, .card_link {
  display: block;
  text-decoration: none;
  outline: none;
  &:hover {
    text-decoration: none;
    border-color: #e6f7fe;
    box-shadow: 0 0 30px rgba(8, 177, 247, 0.1); } }

.card-box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -5px;
  .card {
    width: 50%;
    padding: 5px;
    font-size: 13px;
    line-height: 16px; } }

.card {
  position: relative;
  transition: ease 0.3s;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 20px;
  padding: 30px;
  &__tt {
    color: $cl-mineShaft;
    font-weight: 700; }
  &__txt {
    color: $cl-gray; }
  .icon-circle {
    margin-left: auto;
    margin-right: auto; } }

.icon-circle {
  width: 85px;
  height: 85px;
  margin-bottom: 20px;
  border: 1px solid rgba($cl-cerulean, 0.1);
  background-color: #f0fafe;
  border-radius: 50%;
  position: relative;
  box-shadow: inset 0px 0px 0 5px #fff, inset 0px 0px 0 6px rgba($cl-cerulean, 0.25);
  .icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto; } }

.icon-st {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 1px;
  box-shadow: inset 0 0 0 1px rgba($cl-cerulean, 0.25);
  margin-bottom: 10px;
  &:before, &:after {
    width: 1px;
    height: 7px;
    content: ' ';
    position: absolute;
    z-index: 111;
    background-color: $cl-cerulean; }
  &:before {
    top: 7px;
    left: 0; }
  &:after {
    bottom: 7px;
    right: 0; }
  .icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto; } }
.icon-st_link {
  overflow: hidden;
  background-clip: border-box; }
.icon-st_link:hover {
  &:before {
    animation: {
      name: icon-anim;
      duration: 300ms;
      timing-function: ease-in-out;
      fill-mode: forwards; } }
  &:after {
    animation: {
      name: icon-anim2;
      duration: 300ms;
      timing-function: ease-in-out;
      fill-mode: forwards; } } }

@keyframes icon-anim {
  0% {
    width: 7px;
    height: 1px;
    top: 100%;
    margin-top: -1px;
    left: 36px; }
  50% {
    width: 7px;
    height: 1px;
    left: -7px;
    margin-top: -1px;
    top: 100%; }
  51% {
    top: 100%;
    left: 0;
    height: 7px;
    width: 1px; }
  100% {
    top: 7px;
    left: 0; } }

@keyframes icon-anim2 {
  0% {
    width: 7px;
    height: 1px;
    bottom: 100%;
    margin-bottom: -1px;
    right: 36px; }
  50% {
    width: 7px;
    height: 1px;
    right: -7px;
    margin-top: -1px;
    bottom: 100%; }
  51% {
    bottom: 100%;
    right: 0;
    height: 7px;
    width: 1px; }
  100% {
    bottom: 7px;
    right: 0; } }


/* Min width 768 pixels */
@media only screen and (min-width: 768px) {
  .card-box {
    margin: 0 0px;
    .card {
      width: 50%;
      padding: 10px 15px;
      font-size: 14px;
      line-height: 20px; } }
  /* end media min width 768 pixels */ }


/* Min width 1000 pixels */
@media only screen and (min-width: 1000px) {
  .card-box {
    margin: 0 -4px;
    .card {
      width: 25%;
      padding: 10px 4px;
      font-size: 14px;
      line-height: 20px; } }
  /* end media min width 1000 pixels */ }


/* Min width 1200 pixels */
@media only screen and (min-width: 1200px) {
  .card-box {
    margin: 0 -15px;
    .card {
      width: 25%;
      padding: 30px 20px; } }
  /* end media min width 1200 pixels */ }


/* ----- END Card --------------- */
/*                                */
/*                                */
