/*_________________F A N C Y_______________________*/

@import "standard/fancy";

.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #000;
  cursor: pointer;
  height: 44px;
  margin: 0;
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  z-index: 10;

  svg {
    fill: transparent;
    opacity: .8;
    stroke: currentColor;
    stroke-width: 1.5;
    transition: stroke .1s; }

  &:focus {
    outline: none; }

  &:hover svg {
    opacity: 1; } }

.fancybox-slide--image .fancybox-close-small, .fancybox-slide--video .fancybox-close-small, .fancybox-slide--iframe .fancybox-close-small {
  color: #ccc;
  padding: 5px;
  right: -12px;
  top: -44px; }

.fancybox-slide--image .fancybox-close-small:hover svg, .fancybox-slide--video .fancybox-close-small:hover svg, .fancybox-slide--iframe .fancybox-close-small:hover svg {
  background: transparent;
  color: #fff; }

.fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none; }

.st-modal {
  max-width: 450px;
  width: 95%;
  margin: 0 auto;
  display: none;
  padding: 40px 10px 25px;
  position: relative;
  text-align: center;
  input.form-group__field {
    font-weight: 300; }
  &-line {
    box-shadow: 0 0 20px rgba(8, 177, 247, 0.1);
    border-radius: 10px;
    border: 1px solid #d4effa;
    background-color: #fcfeff;
    height: 15px;
    overflow: hidden;
    position: relative;
    margin-bottom: 35px;
    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 50%;
      bottom: 0;
      background-color: #08bff7;
      background-image: linear-gradient(to right, $cl-cerulean 0%, #08d2f7 100%); } }
  &-tt {
    color: $cl-dark;
    font-family: $site-font-family-2;
    font-size: 23px;
    line-height: 1.1;
    font-weight: 700;
    margin-bottom: 10px; }
  &-txt {
    color: $cl-gray;
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
    margin-bottom: 20px; }
  .title-site, h1, h2, h3 {
    font-size: 26px;
    text-align: center; }
  .form-group_btn {
    margin-top: 20px;
    input.btn {
      padding-top: 0;
      padding-bottom: 0;
      height: 70px;
      width: 100%; } } }

.fancybox-is-open .fancybox-bg {
  opacity: 0.78; }



/* Min width 768 pixels */
@media only screen and (min-width: 768px) {
  .fancybox-close-small {
    padding: 0;
    color: #000;
    width: 40px;
    height: 40px; }
  .st-modal {
    padding-left: 40px;
    padding-right: 40px; }
  /* end media min width 768 pixels */ }


// mainstyles
.fancybox-inner {
  .fancybox-toolbar {
    .fancybox-button.fancybox-button--zoom, .fancybox-button.fancybox-button--thumbs {
      display: none !important; } } }

/*_____________end F A N C Y_______________________*/
