/* ----- Other style ------------------- */

.border-bottom {
  border-bottom: 1px solid #ecf2f7; }

.text-bl-grid_line {
  border-bottom: 1px solid #ecf2f7;
  padding-bottom: 1px;
  margin-bottom: 20px; }

.card-box_next-style {
  margin-bottom: 40px;
  .card {
    border-color: #d7f2fe;
    padding: 20px 10px;
    margin-bottom: 0; }
  a.card {
    &:hover {
      box-shadow: 0px 9px 30px rgba(8, 177, 247, 0.15); } } }

.preview-box {
  > img {
    margin-bottom: 40px; } }

.main-list {
  margin-bottom: 20px;
  li {
    color: $cl-cerulean;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 10px;
    width: 250px;
    padding-right: 10px;
    display: inline-block;
    vertical-align: top;
    a {
      color: $cl-cerulean;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: #006199; } } } }

.main-catalog-item__img {
  display: none; }

.main-catalog-item {
  margin-bottom: 25px; }

.grid-btn__item {
  margin-bottom: 20px; }


/* Min width 480 pixels */
@media only screen and (min-width: 480px) {
  .card-box_next-style {
    .card, .card__tt {
      &:before, &:after {
        content: ' ';
        position: absolute;
        width: 10px;
        height: 10px;
        border-top: 1px solid $cl-cerulean;
        top: -1px; }
      &:before {
        left: -1px;
        border-left: 1px solid $cl-cerulean; }
      &:after {
        right: -1px;
        border-right: 1px solid $cl-cerulean; } }
    .card__tt {
      &:before, &:after {
        border-top: none;
        border-bottom: 1px solid $cl-cerulean;
        top: auto;
        bottom: -1px; }
      &:before {
        left: -1px; }
      &:after {
        right: -1px; } } }
  .card-box_next-style.card-box {
    margin: 0 -1% 20px;
    .card {
      width: 48%;
      margin: 0 1% 10px; } }
  .main-catalog-item__img {
    display: block;
    flex-shrink: 0;
    width: 120px;
    margin-right: 20px; }
  .main-catalog-item {
    display: flex; }

  /* end media min width 480 pixels */ }


/* Min width 640 pixels */
@media only screen and (min-width: 640px) {
  .main-catalog-item__content {
    .main-list {
      li {
        width: 180px; } } }
  .grid-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 40px;
    .grid-btn__item {
      width: 49%;
      &:last-child {
        text-align: right;
        .btn {
          margin-left: auto; } }

      .btn {
        width: 100%;
        min-width: 100px;
        max-width: 330px; } } }
  /* end media min width 640 pixels */ }


/* Min width 768 pixels */
@media only screen and (min-width: 768px) {
  .text-bl-grid_line {
    padding-bottom: 40px;
    margin-bottom: 40px; }
  .card-box_next-style.card-box {
    margin: 0 -1% 20px;
    .card {
      width: 23%;
      margin: 0 1% 10px; } }
  .main-bl {
    margin-top: 35px; }
  .preview-box > img {
    margin-bottom: 20px; }
  /* end media min width 768 pixels */ }


/* Min width 1000 pixels */
@media only screen and (min-width: 1000px) {
  .card-box.card-box_next-style {
    margin-top: 10px;
    .card {
      padding: 30px; } }
  .main-bl {
    .quote-box {
      margin-top: 50px; } }
  .main-list {
    li {
      font-size: 14px;
      width: 340px;
      padding-right: 20px; } }
  .main-catalog-item__img {
    width: 200px;
    max-width: 200px;
    margin-right: 40px; }
  .main-catalog-item {
    margin-bottom: 55px;
    .main-title {
      margin-bottom: 30px; }
    .text_shadow {
      margin-bottom: 30px; }
    .main-list {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        max-width: 280px; } } }

  /* end media min width 1000 pixels */ }

/* ----- END Other style --------------- */
/*                                       */
/*                                       */
