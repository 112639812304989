/* ----- Sprite icons ------------------- */
a.icon {
  text-decoration: none;
  outline: none; }

.icon {
  display: inline-block;
  vertical-align: middle;
  background-image: url(../img/sprite.png);
  background-repeat: no-repeat; }

.icon-ic1 {
  width: 22px;
  height: 19px;
  background-position: -10px -10px; }

.icon-ic10 {
  width: 28px;
  height: 32px;
  background-position: -52px -10px; }

.icon-ic11 {
  width: 33px;
  height: 33px;
  background-position: -100px -10px; }

.icon-ic12 {
  width: 26px;
  height: 30px;
  background-position: -153px -10px; }

.icon-ic2 {
  width: 23px;
  height: 22px;
  background-position: -153px -60px; }

.icon-ic3 {
  width: 20px;
  height: 20px;
  background-position: -10px -60px; }

.icon-ic4 {
  width: 30px;
  height: 29px;
  background-position: -10px -102px; }

.icon-ic5 {
  width: 27px;
  height: 30px;
  background-position: -60px -102px; }

.icon-ic6 {
  width: 30px;
  height: 30px;
  background-position: -107px -102px; }

.icon-ic7 {
  width: 31px;
  height: 31px;
  background-position: -157px -102px; }

.icon-ic8 {
  width: 10px;
  height: 30px;
  background-position: -10px -153px; }

.icon-ic9 {
  width: 32px;
  height: 32px;
  background-position: -40px -153px; }

.icon-side1 {
  width: 16px;
  height: 14px;
  background-position: -92px -153px; }

.icon-side1-1 {
  width: 47px;
  height: 49px;
  background-position: -128px -153px; }

.icon-side2 {
  width: 16px;
  height: 16px;
  background-position: -199px -10px; }

.icon-side2-1 {
  width: 47px;
  height: 49px;
  background-position: -195px -153px; }

.icon-side3 {
  width: 16px;
  height: 16px;
  background-position: -199px -46px; }

.icon-side3-1 {
  width: 47px;
  height: 49px;
  background-position: -235px -10px; }

.icon-user {
  width: 11px;
  height: 11px;
  background-position: -235px -79px; }

.icon-user-active {
  width: 11px;
  height: 11px;
  background-position: -266px -79px; }

.icon-user-danger {
  width: 11px;
  height: 11px;
  background-position: -297px -79px; }


/* ----- END Sprite icons --------------- */
/*                                        */
/*                                        */
