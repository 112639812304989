/* ----- Index page -------------------- */

.section-banner {
  background: url(../img/banner.jpg) center center no-repeat;
  background-size: cover;
  color: #fff; }

.banner {
  min-height: 400px;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  justify-content: center; }

.title-wrapp {
  padding-left: 20px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  &:before, &:after {
    position: absolute;
    content: ' ';
    top: 5px; }
  &:before {
    left: 0;
    width: 1px;
    z-index: -2;
    height: 100%;
    background-color: #1a5168; }
  &:after {
    left: -1px;
    width: 3px;
    height: 48px;
    background-color: $cl-cerulean; }
  &_light {
    .title-site, h1, h2 {
      color: #fff;
      .title-site_cerulean {
        color: $cl-cerulean; } } }

  &__txt {
    font-family: $site-font-family-2;
    color: #a3a5a5;
    font-size: 23px;
    line-height: 1;
    font-weight: 300; } }

.sidebar {
  margin-bottom: 50px; }

.main-icons {
  margin-bottom: 30px;
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    .icon-st {
      flex-shrink: 0;
      margin-right: 20px;
      margin-bottom: 0; } } }

.main-icons-txt {
  display: inline-block;
  color: $cl-light;
  font-size: 16px; }

.sidebar-box {
  margin-bottom: 20px; }

.sidebar-box-cap {
  border-radius: 1px;
  background-color: $cl-cerulean;
  position: relative;
  z-index: 1;
  padding: 13px 65px;
  min-height: 54px;
  &__name {
    color: #fffefe;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    min-height: 28px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    &:hover {
      color: #fffefe;
      text-decoration: underline; } }
  .icon_first {
    position: absolute;
    z-index: -1;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 30px; }
  .icon_last {
    position: absolute;
    top: 0;
    right: 0; } }

.sidebar-box__list {
  border: 1px solid #dce5ef;
  border-top: none;
  background-color: $cl-light;
  padding: 10px 25px;
  position: relative;
  z-index: 1;
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 5px;
    left: 5px;
    right: 5px;
    border: 1px solid rgba(#dce5ef, 0.3);
    border-top: none;
    z-index: -1; }
  li {
    color: #646464;
    font-size: 14px;
    line-height: 16px;
    margin: 10px 0;
    a {
      color: #646464;
      text-decoration: underline;
      &:hover {
        color: $cl-mineShaft;
        text-decoration: none; } } } }

.main-title, .main-title-inline .title-site, .main-title-inline h1, .main-title-inline h2, .main-title-inline h3 {
  padding-bottom: 15px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  &:before, &:after {
    content: ' ';
    position: absolute;
    left: 0; }
  &:before {
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: rgba($cl-cerulean, 0.3); }
  &:after {
    background-color: $cl-cerulean;
    width: 80px;
    height: 3px;
    bottom: -1px; } }
.main-title {
  .title-site, h1, h2, h3, h4 {
    margin-bottom: 0; } }

.main-title-inline .title-site, .main-title-inline h1, .main-title-inline h2, .main-title-inline h3 {
  display: inline-block;
  &:after {
    width: 50px; } }

.section-preview {
  background: #000 url(../img/preview.jpg) center center no-repeat;
  background-size: cover;
  color: #fff; }

.preview {
  padding: 40px 0;
  &__img {
    max-width: 200px;
    margin: 0 auto 30px; }
  p {
    color: $cl-nobel; } }

.main-title_light {
  .title-site, h1, h2, h3 {
    color: $cl-light; } }

.why {
  border-bottom: 1px solid rgba(#dce5ef, 0.5);
  padding-bottom: 1px;
  margin-bottom: 40px; }

.main-title_sm.main-title {
  &:before {
    max-width: 330px; }
  &:after {
    width: 50px; } }

.text_shadow {
  color: $cl-gray;
  font-size: 15px;
  line-height: 22px; }

.quote-box {
  border: 1px solid #dce5ef;
  background-color: #ffffff;
  margin: 0 -15px;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 4px #fff, inset 0 0 0 5px rgba(#dce5ef, 0.3);
  &__icon {
    flex-shrink: 0;
    margin-right: 15px; }
  &__txt {
    color: $cl-gray;
    font-size: 14px;
    line-height: 18px; } }

.section-preview_bg2 {
  background-image: url(../img/preview2.jpg);
  background-position: right center; }

.preview {
  .btn {
    margin-top: 20px; } }

.quote-box {
  .icon-st {
    width: 82px;
    height: 82px;
    margin-bottom: 0;
    background-color: #f3fbfe;
    box-shadow: inset 0 0 0 4px #fff, inset 0 0 0 5px #c1ecfd;
    &:before {
      top: 15px;
      left: 4px; }
    &:after {
      bottom: 15px;
      right: 4px; } } }


/* Min width 640 pixels */
@media only screen and (min-width: 640px) {
  .quote-box {
    margin-top: 40px;
    &__txt {
      color: $cl-gray;
      font-size: 15px;
      line-height: 22px; } }
  /* end media min width 640 pixels */ }


/* Min width 768 pixels */
@media only screen and (min-width: 768px) {
  .title-wrapp {
    padding-left: 30px; }

  .main-title {
    &:after {
      width: 150px; } }

  .banner {
    min-height: 600px;
    .title-wrapp {
      margin-bottom: 60px; } }

  .middle-grid {
    display: flex;
    justify-content: space-between;
    &__aside {
      width: 28%; }
    &__content {
      width: 69%; } }

  .sidebar-box-cap {
    padding-left: 40px;
    padding-right: 50px; }
  .sidebar-box-cap .icon_first {
    left: 20px; }
  .preview {
    position: relative;
    z-index: 1;
    padding-left: 280px;
    .preview__img {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      width: 250px;
      max-width: none; } }
  .title-wrapp {
    .title-site_h1, h1 {
      font-size: 40px; } }
  .sidebar-box__list li {
    margin: 18px 0; }
  .main-title_push-lg {
    margin-bottom: 30px; }
  .main-title-inline .title-site, .main-title-inline h1, .main-title-inline h2, .main-title-inline h3 {
    margin-bottom: 30px; }

  .quote-box {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
    padding: 40px;
    padding-right: 70px;
    &__icon {
      margin-right: 40px; } }

  .preview-box {
    margin-bottom: 20px; }

  .section-preview_bg2 {
    background-position: 42% 50%; }

  /* end media min width 768 pixels */ }

/* Min width 1000 pixels */
@media only screen and (min-width: 1000px) {
  .title-wrapp {
    padding-left: 30px; }

  .main-title {
    &:after {
      width: 150px; } }

  .banner {
    min-height: 600px;
    .title-wrapp {
      margin-bottom: 60px; } }

  .middle-grid {
    display: flex;
    justify-content: space-between;
    &__aside {
      width: 230px; }
    &__content {
      width: 700px; } }

  .sidebar-box-cap {
    padding-left: 40px;
    padding-right: 50px; }
  .sidebar-box-cap .icon_first {
    left: 20px; }
  .main-title_md {
    &:before {
      width: 560px; } }
  .banner {
    min-height: 650px;
    padding: 70px 0 80px; }
  .title-wrapp {
    .title-site_h1, h1 {
      margin-bottom: 30px;
      font-size: 55px; } }
  .main-icons li .icon-st {
    margin-right: 40px; }
  .main-icons {
    margin-bottom: 50px; }
  .banner__link {
    .btn {
      min-width: 445px; } }
  .main-title_push-md {
    margin-bottom: 26px; }
  .main-title_push-lg {
    margin-bottom: 40px; }

  .section-preview_bg2 {
    background-position: 52% 50%; }
  .preview .btn {
    margin-top: 36px; }
  .preview {
    .btn_big {
      min-width: 460px; } }
  /* end media min width 1000 pixels */ }


/* Min width 1200 pixels */
@media only screen and (min-width: 1200px) {
  .middle-grid__aside {
    width: 255px; }
  .middle-grid__content {
    width: 800px; }
  .sidebar-box-cap {
    padding-left: 65px;
    padding-right: 55px; }
  .sidebar-box-cap .icon_first {
    left: 39px; }
  .preview {
    padding: 65px 0 78px 432px; }
  .preview .preview__img {
    width: 620px;
    left: -220px;
    transform: translate(0, -330px); }
  .section-preview_bg2 {
    background-position: center center; }
  /* end media min width 1200 pixels */ }

/* ----- END index page /--------------- */
/*                                       */
/*                                       */
