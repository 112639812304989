/* ----- Styles site ------------------- */
//helpers
@import "helpers/mixins";
@import "helpers/const";
@import "helpers/sprite-mixins";
@import "helpers/normalize";
// @import helpers/fonts
@import "helpers/sprite";


//layout
@import "layout/typography";
@import "layout/table";
@import "layout/layout";
@import "layout/header";
@import "layout/footer";


//components
@import "components/controls";
@import "components/forms";
@import "components/main-navigation";
// @import plugins/anim-dd
@import "components/card";


//plugins
// @import plugins/jcf
// @import plugins/owl-carousel
@import "plugins/fancy";

// @import plugins/map
// @import plugins/mCustomScrollbar
// @import plugins/name


//pages
@import "pages/index";
@import "pages/inner";

/* ----- END Styles site --------------- */
