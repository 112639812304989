/* ----- Header ------------------------ */
.header {
  position: relative;
  &-top {
    padding: 25px 0; } }

.header-top-box {
  box-shadow: 0 0 20px rgba(11, 119, 164, 0.1); }

.header-top__logo {
  .logo-txt {
    display: none; } }

.logo-txt {
  color: #909090;
  font-size: 12px;
  line-height: 16px; }

.header-top__contact {
  display: none; }

.logo {
  max-width: 185px;
  a {
    display: block;
    outline: none; } }

.main-box {
  position: relative;
  z-index: 1;
  padding-left: 10px;
  &__name {
    color: $cl-mineShaft;
    font-family: $site-font-family-2;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 5px;
    font-weight: 700;
    text-transform: uppercase; }
  &__txt {
    color: #646464;
    font-size: 14px;
    line-height: 20px; }
  &:before, &:after {
    content: ' ';
    position: absolute;
    top: 0;
    z-index: -1; }
  &:before {
    left: 0;
    width: 1px;
    height: 40px;
    background-color: #ceeffd; }
  &:after {
    left: -1px;
    width: 3px;
    height: 15px;
    background-color: $cl-cerulean; } }

a.main-box__txt {
  color: #646464;
  &:hover {
    text-decoration: none; } }


/* Min width 768 pixels */
@media only screen and (min-width: 768px) {
  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0; }
  .header-top__contact {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      margin-left: 50px; } }
  .header {
    box-shadow: 0 0 20px rgba(11, 119, 164, 0.1); }

  /* end media min width 768 pixels */ }


/* Min width 1000 pixels */
@media only screen and (min-width: 1000px) {
  .header-top__contact {
    .main-box {
      margin-left: 50px; } }
  .header-top__contact .btn {
    margin-left: 65px; }
  /* end media min width 1000 pixels */ }

/* Min width 1200 pixels */
@media only screen and (min-width: 1200px) {
  .header-top__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .logo {
      margin-right: 35px; }
    .logo-txt {
      display: block; } }
  /* end media min width 1200 pixels */ }


/* ----- END Header -------------------- */
/*                                       */
/*                                       */
