// colors
$cl-site: #000e14;
$cl-site-second: #f47b2c;

$cl-light: #fff;
$cl-light-2: #ffffff;
$cl-dark: #000;
$cl-gray: #808080;
$cl-emperor: #555;
$cl-mineShaft: #222;
$cl-green: #00BD12;
$cl-porsche: #eab15d;
$cl-blue: #1f30ff;
$cl-scarlet: #ff2c1a;
$cl-canary: #fdfd58;
$cl-silver: #ccc;
$cl-alto: #ddd;
$cl-gallery: #eee;
$cl-silverChalice: #aaa;
$cl-tacao: #efae7c;
$cl-tango: #f47b2c;
$cl-cerulean: #08b1f7;
$cl-nobel: #b3b3b3;


// Typography var
$site-font-style: normal;   //normal | italic | oblique | inherit
$site-font-variant: normal; //normal | small-caps | inherit
$site-font-weight: 400;
$site-fill-color: #fff;

$site-font-family: 'Roboto', Arial, sans-serif;
$site-font-family-2: 'Roboto Condensed', Arial, sans-serif;

$body-text-size: 15+(px)+/20+(px);
$site-text-size: 14+(px);
$site-line-height: 20+(px);

// $font-base: 'Roboto'
// $font-second: 'Roboto'

$site-text-color: $cl-mineShaft;

// Title site variables
$site-title-color: $cl-mineShaft;
$title-font-weight: $site-font-family-2;
$title-font-family: 700;
$title-line-height: 1.2+(em);

$site-h1-size-mobile: 30+(px);
$site-h2-size-mobile: 26+(px);
$site-h3-size-mobile: 19+(px);
$site-h4-size-mobile: 18+(px);
$site-h5-size-mobile: 17+(px);
$site-h6-size-mobile: 16+(px);

$site-h1-size: 55+(px);
$site-h2-size: 30+(px);
$site-h3-size: 19+(px);
$site-h4-size: 18+(px);
$site-h5-size: 17+(px);
$site-h6-size: 16+(px);


// Buttons variables
$btn-fill-color: $cl-tango;
$btn-fill-hover-color: lighten($btn-fill-color, 5%);
$btn-fill-second-color: $cl-site-second;
$btn-fill-second-hover-color: lighten($btn-fill-second-color, 5%);
$btn-color: #fff;
$btn-color-hover: #fff;

$btn-radius: 1+(px);
$btn-font: normal 700 16+px+/+24+px $site-font-family-2;
$btn-padding: ((70-24)/2)+px 30px;
$btn-width-min: 290px;

//border btn
$btn-border: 1px solid $btn-fill-color;
$btn-border-hover-color: $btn-fill-color;
$btn-border-line-height: 22px;
$btn-border-color-text: $btn-fill-color;
$btn-border-hover-color-text: $btn-color;

//sm
$btn-padding-sm: ((46-24)/2)+px 25px;
$btn-width-min-sm: 185px;
//md
$btn-padding-md: ((76-24)/2)+px 25px;
$btn-width-min-md: 150px;
//lg
$btn-padding-lg: ((80-24)/2)+px 30px;
$btn-width-min-lg: 200px;
//font-radius-sm
$btn-size-sm: 12px;
//font-radius-md
$btn-size-md: 16px;
//font-radius-lg
$btn-size-lg: 18px;
//font-family-bold
$btn-font-family-bold: $site-font-family;
$btn-font-weight-bold: 700;
//font-family-light
$btn-font-family-light: $site-font-family;
$btn-font-weight-light: 300;

// links variables
$color-link: $cl-cerulean;
$color-h-link: #006199;

// color .placeholder
$st-color-placeholder: #c4c4c4;

// Field Forms variables Input and Textarea
$input-height: 50+(px);
$input-padding: 0 20+(px);
$input-border: 1px solid #d4effa;
$input-font: 13px $site-font-family;
$input-color: $cl-mineShaft;
$input-fill-color: #fcfeff;
$input-radius: 1px;
$input-shadow: 0 0 20px rgba(8, 177, 247, 0.1);

$input-hover-border-color: #a8e0f7;
$input-focus-border-color: #a8e0f7;

// Navigation
$burger-fill: $cl-dark;
$burger-fill-open: $cl-light;

$font-menu-item-mobile: normal 500 20+(px)+/24+(px) $site-font-family;
$font-menu-item: normal 500 13+(px)+/18+(px) $site-font-family;
$fill-menu: $cl-site;
$fill-menu-hover: darken($cl-site, 5%);

// Field Forms textarea
$textarea-height: 130+(px);
$textarea-padding: 10+(px) 15+(px);

// breakpoint
$mob: 375px;
$mob-md: 480px;
$mob-lg: 640px;
$tabl: 768px;
$lap: 1000px;
$desk: 1200px;
$desk-md: 1600px;
$desk-lg: 1900px;

