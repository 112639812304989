/* ----- Typography -------------------- */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: $site-fill-color;
  font: $site-font-style $site-font-weight $body-text-size $site-font-family;
  color: $site-text-color; }

.title-site, h1, h2, h3, h4, h5, h6 {
  display: block;
  margin: 0 0 15px;
  padding: 0;
  font-family: $title-font-weight;
  font-weight: $title-font-family;
  line-height: 1.1;
  color: $site-title-color; }

h1, .title-site_h1 {
  font-size: $site-h1-size-mobile;
  margin-bottom: 20px; }

h2, .title-site_h2 {
  font-size: $site-h2-size-mobile;
  margin-bottom: 20px; }

h3, .title-site_h3 {
  font-size: $site-h3-size-mobile; }

h4, .title-site_h4 {
  font-size: $site-h4-size-mobile; }

h5, .title-site_h5 {
  font-size: $site-h5-size-mobile;
  line-height: 1.3em; }

h6, .title-site_h6 {
  font-size: $site-h6-size-mobile;
  line-height: 1.3em; }

ul, ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

li {
  margin: 0;
  padding: 0; }

p {
  margin: 0 0 20px; }

.text-bl {
  ul {
    margin-bottom: 20px;
    list-style-type: disc;
    padding-left: 15px; }

  ol {
    margin-bottom: 20px;
    list-style-type: decimal;
    padding-left: 15px; }

  li {
    margin-bottom: 10px; }

  > .btn {
    margin-top: 20px; } }

.text_center {
  text-align: center; }

.text_left {
  text-align: left; }

.text_right {
  text-align: right; }

.text_uppercase {
  text-transform: uppercase; }


img {
  vertical-align: middle;
  max-width: 100%; }

a {
  color: $color-link;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: $color-h-link; } }

.text-bl-grid__half-col {
  margin-bottom: 40px; }

.text-bl-grid__col-half {
  margin-bottom: 40px; }



/* Min width 768 pixels */
@media only screen and (min-width: 768px) {
  h1, .title-site_h1 {
    font-size: $site-h1-size; }

  h2, .title-site_h2 {
    font-size: $site-h2-size; }

  .text-bl-grid {
    display: flex;
    justify-content: space-between;

    &__half-col {
      width: 48%;
      margin-bottom: 0; }
    &__col-half {
      width: 50%;
      padding-right: 20px;
      margin-bottom: 0;
      &:last-child {
        padding-right: 0;
        padding-left: 20px; } } }
  .text-bl {
    p {
      line-height: 22px; }
    &_line {
      min-height: 550px;
      position: relative;
      padding-bottom: 30px;
      margin-bottom: 30px;
      &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        right: -20px;
        height: 1px;
        background-color: #ecf2f7; } } }
  .text-bl-grid__col-half:last-child {
    .text-bl_line:after {
      right: 0;
      left: -20px; } }



  /* end media min width 768 pixels */ }


/* Min width 1000 pixels */
@media only screen and (min-width: 1000px) {
  .text-bl-grid {
    &__col-half {
      width: 50%;
      padding-right: 50px;
      &:last-child {
        padding-left: 20px; } } }

  .text-bl {
    p {
      line-height: 22px; }
    &_line {
      min-height: 480px;
      &:after {
        right: -50px; } } }
  .text-bl-grid__col-half:last-child {
    .text-bl_line:after {
      left: -20px; } }
  /* end media min width 1000 pixels */ }


/* Min width 1200 pixels */
@media only screen and (min-width: 1200px) {
  .text-bl-grid {
    &__col-half {
      width: 50%;
      padding-right: 50px;
      &:last-child {
        padding-left: 20px; } } }

  .text-bl {
    p {
      line-height: 22px; }
    &_line {
      min-height: 410px;
      &:after {
        right: -50px; } } }
  .text-bl-grid__col-half:last-child {
    .text-bl_line:after {
      left: -20px; } }
  /* end media min width 1200 pixels */ }

/* ----- END Typography ---------------- */
/*                                       */
/*                                       */






