/* ----- table ------------------- */

.table-st {
  margin: 0 -15px 40px; }


table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  th {
    background-color: $cl-cerulean;
    padding: 5px 0;
    color: $cl-light;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    &:last-child {
      span:after {
        display: none; } }
    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 5px;
      padding-right: 5px;
      min-height: 38px;
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        right: -1px;
        width: 1px;
        background-color: rgba(#dce5ef, 0.3);
        top: 0;
        bottom: 0; } } }
  td {
    color: #646464;
    font-size: 10px;
    background-color: #fff;
    font-weight: 400;
    padding: 10px 5px;
    border: 1px solid #dce5ef;
    img {
      max-width: 50px; } } }


/* Min width 480 pixels */
@media only screen and (min-width: 480px) {
  table {
    th {
      font-size: 14px;
      padding-top: 8px;
      padding-bottom: 8px; }
    td {
      font-size: 14px;
      padding: 15px 5px;
      img {
        max-width: 100px; } } }
  .table-st__img {
    td {
      padding: 10px 5px; } }
  /* end media min width 480 pixels */ }

/* Min width 480 pixels */
@media only screen and (min-width: 480px) {
  .table-st {
    margin-left: 0;
    margin-right: 0; }
  /* end media min width 480 pixels */ }


/* Min width 1000 pixels */
@media only screen and (min-width: 1000px) {
  .table-st__img {
    tr {
      td:first-child, th:first-child {
        width: 175px; } } }
  /* end media min width 1000 pixels */ }

/* ----- END table --------------- */
/*                                 */
/*                                 */
