/* ----- Footer ------------------------ */
.footer {
  padding: 20px 0;
  background-color: $cl-site;
  .main-box__name {
    color: #fff; }
  .main-box__txt {
    color: #b2b7b8; }
  .main-box:before {
    background-color: #022f41; } }

.f-box {
  border-bottom: 1px solid #2c3940;
  padding: 20px 0; }

.f-box-item {
  margin-bottom: 20px;
  .logo {
    margin-bottom: 20px; } }

.f-list-box {
  padding: 20px 0;
  &__item {
    margin-bottom: 30px; } }

.f-list-name {
  color: #fffefe;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 20px;
  a {
    color: #fffefe;
    text-decoration: none;
    outline: none;
    &:hover {
      text-decoration: underline; } } }


.f-list {
  li {
    color: #4e5356;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
    a {
      color: #4e5356;
      text-decoration: underline;
      &:hover {
        color: #fff;
        text-decoration: none; } } } }


.f-list-link {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  a {
    display: block;
    color: #08B1F7;
    text-transform: uppercase; } }

/* Min width 480 pixels */
@media only screen and (min-width: 480px) {
  .f-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    &-item {
      width: 220px;
      margin-bottom: 30px;
      &:last-child {
        margin-right: 0; } } }

  .f-list-box {
    display: flex;
    flex-wrap: wrap;
    &__item {
      width: 50%;
      padding-right: 10px; } }
  /* end media min width 480 pixels */ }

/* Min width 640 pixels */
@media only screen and (min-width: 640px) {
  .f-box {
    &-item {
      width: 300px; } }
  /* end media min width 640 pixels */ }


/* Min width 768 pixels */
@media only screen and (min-width: 768px) {
  .f-list-box {
    &__item {
      width: 33%; } }
  .f-box {
    &-item {
      width: 240px; } }
  /* end media min width 768 pixels */ }


/* Min width 1000 pixels */
@media only screen and (min-width: 1000px) {
  .f-box {
    justify-content: space-between;
    margin-right: -10px;
    padding: 40px 0 10px;
    &-item {
      width: auto;
      margin-right: 10px; } }

  .f-list-box {
    padding: 35px 0;
    &__item {
      width: 25%; } }

  .f-list-box__item_last-child {
    .f-list-name {
      width: 134px;
      margin-left: auto; } }
  .f-list-box__item {
    margin-bottom: 0; }

  .f-list-link {
    margin-top: 110px;
    margin-bottom: 0; }

  /* end media min width 1000 pixels */ }


/* Min width 1200 pixels */
@media only screen and (min-width: 1200px) {
  .f-list-box {
    justify-content: space-between; }
  .f-list-box__item {
    width: 300px;
    &:nth-child(2) {
      padding-left: 55px; } }
  .f-list-box__item_last-child {
    width: 200px;
    .f-list-name {
      margin-bottom: 16px; } }
  .f-list-name {
    margin-bottom: 30px; }
  .f-list li {
    margin-bottom: 12px; }
  .f-list-box__item_last-child {
    .f-list-link {
      margin-bottom: 0; } }
  .f-list-link {
    margin-top: 100px; }
  /* end media min width 1200 pixels */ }


/* ----- END Footer -------------------- */
/*                                       */
/*                                       */
