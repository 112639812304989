// SASS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home
//   width: $icon-home-width
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites
$icon-right-name: 'icon-right';
$icon-right-x: 0px;
$icon-right-y: 0px;
$icon-right-offset-x: 0px;
$icon-right-offset-y: 0px;
$icon-right-width: 52px;
$icon-right-height: 8px;
$icon-right-total-width: 52px;
$icon-right-total-height: 8px;
$icon-right-image: 'sprite.png';
$icon-right: (0px, 0px, 0px, 0px, 52px, 8px, 52px, 8px, 'sprite.png', 'icon-right', );
$spritesheet-width: 52px;
$spritesheet-height: 8px;
$spritesheet-image: 'sprite.png';
$spritesheet-sprites: ($icon-right, );
$spritesheet: (52px, 8px, 'sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home
//   @include sprite-width($icon-home)
//
// .icon-email
//   @include sprite($icon-email)
//
// Example usage in HTML:
//   `display: block` sprite:
//   <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//   // CSS
//   .icon {
//     display: inline-block;
//   }
//
//   // HTML
//   <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5); }

@mixin sprite-height($sprite) {
  height: nth($sprite, 6); }

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y; }

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(../img/#{$sprite-image}); }

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite); }

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SASS
//
// @include sprites($spritesheet-sprites)
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite); } } }
