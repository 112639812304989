/* ----- Forms ------------------------- */
.st-form {
  margin-bottom: 20px;

  &__tt {
    display: block; } }


.form-group {
  margin-bottom: 15px;

  &__label {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px; }

  &_inline {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-group__label {
      min-width: 140px;
      max-width: 140px;
      padding: 0 10px 0 0;
      margin: 5px 0; } }

  &_icon {
    position: relative;
    .icon {
      position: absolute;
      top: 50%;
      margin-top: -6px;
      right: 19px;
      &:before {
        content: ' ';
        position: absolute;
        top: 50%;
        height: 30px;
        right: 30px;
        width: 1px;
        background-color: #d4effa;
        margin-top: -15px; } }
    input {
      padding-right: 60px;
      &:hover, &:focus {
        + .icon {
          background-position: -266px -79px;
          &:before {
            background-color: #a8e0f7; } } } } }
  &_icon-danger.form-group_icon {
    input {
      border-color: #fa4f68;
      + .icon {
        background-position: -297px -79px;
        &:before {
          background-color: #fa4f68; } } }
    .placeholder {
      color: $cl-mineShaft; }
    input,
    textarea {
      @include placeholder-color($cl-mineShaft); } } }



/* Min width 768 pixels */
@media only screen and (min-width: 768px) {
  .form-group-grid {
    display: flex;
    justify-content: space-between;

    &__half-col {
      width: 48.5%; }

    &__three-col {
      width: 31.3%; }

    &__four-col {
      width: 22.8%; } }
  /* end media min width 768 pixels */ }


/* ----- END Forms --------------------- */
/*                                       */
/*                                       */


